<template>
  <section class="experience">
    <el-row>
      <el-col :span="12">
        <section v-if="options.position == 'left'? true : false" class="relative" :style="{height: relativeHeight + 'px'}">
          <div class="w66">
            <h3 class="one_hidden">{{ options.title }}</h3>
            <p class="tr_hidden">{{ options.content }}</p>
            <router-link :to="options.url">了解更多 <i class="el-icon-right"></i></router-link>
          </div>
        </section>
        <el-image :src="options.src" v-else></el-image>
      </el-col>
      <el-col :span="12">
        <el-image :src="options.src" v-if="options.position == 'left'? true : false"></el-image>
        <section class="relative" v-else :style="{height: relativeHeight + 'px'}">
          <div class="w66">
            <h3 class="one_hidden">{{ options.title }}</h3>
            <p class="tr_hidden">{{ options.content }}</p>
            <router-link :to="options.url">了解更多 <i class="el-icon-right"></i></router-link>
          </div>
        </section>
      </el-col>
    </el-row>
  </section>
</template>

<script>
export default {
  name: 'experience',
  props: {
    options: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      relativeHeight: null
    }
  },
  created () {
    this.setHeight()
  },
  methods: {
    setHeight () {
      let w = (document.documentElement || document.body).clientWidth
      this.relativeHeight = w >= 1920 ? (1920 - w * .2) / 2 * 743 / 838 : w * 0.8 / 2 * 743 / 838
    }
  },
  mounted () {
    window.addEventListener("resize", this.setHeight, false)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.setHeight, false)
  }
}
</script>

<style scoped>
.experience {max-width: 1920px; margin: 0 auto;}
.experience, .experience .el-row, .experience .el-row .el-col, .relative {height: 100%; background-color: #f0f3f6;}
.experience .el-image, .experience /deep/ img {width: 100%; padding: 0; display: block;}
.relative {display: flex; justify-content: center; align-items: center; font-size: 1.2rem; min-height: 8rem; vertical-align: middle;}
.w66 {width: 80%; text-align: left; padding: 1rem; box-sizing: border-box;}
.relative h3 {line-height: 2.5; border-bottom: 1px solid #a7a8a8; font-weight: 600; font-size: 1.6rem;}
.relative  p {margin: 1rem 0; font-size: 1rem; line-height: 1.6; color: #999;}
.relative a {padding: .4rem 1.2rem; color: #fff; border-radius: 2rem; background-color: #51070b; font-size: .8rem;}
.relative .el-icon-right {font-size: .8rem; display: inline-block; padding: .1rem; margin-left: .6rem; border: 1px solid #fff; background-color: #fff; color: #51070b; border-radius: 50%;}
</style>