<template>
  <section class="new-product">
    <imageBanner :imgBannerObj="imgBannerObj" />
    <div class="container">
      <p class="title">公司紧密围绕国家电网公司关于构建新型电力系统提出的电网设备远距离可观可测可控的管理需求，成功开发一系列变/配电设备智能监控、远程巡视类产品，并在各电压等级变电站中投入使用，核心产品包括变电站智慧辅控管理平台、变电站远程智能巡视系统和电子安防综合管理平台，同时还针对电网设备运行微环境改善和对设备异常进行连续监测开发出一些列产品和解决方案。</p>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="平台产品系列" name="first">
          <ExperienceView v-for="(item, index) in platform" :key="index" :options="item" />
        </el-tab-pane>
        <el-tab-pane label="设备运行监护系列" name="second">
          <ExperienceView v-for="(item, index) in guardianship" :key="index" :options="item" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </section>
</template>

<script>
import imageBanner from '../components/ImageBanner.vue'
import ExperienceView from '../components/ExperienceView.vue';

export default {
  components: {imageBanner,ExperienceView},
  data () {
    return {
      imgBannerObj: {
        url: require('../assets/images/2023/product/导航图.png'),
        slogan: require('../assets/images/common/slogan.png'),
      },
      platform: [
        // {position: 'left', title: '变电站综合监控平台', content: '变电站辅助设备监控系统采用开放式系统，系统设备配置和功能满足无人值班技术要求', url: {path: '/productDetails', query: {id: 1}} , src: require('../assets/images/product/变电站综合检测平台_画板 1.png')},
        // {position: 'right', title: '红外重症监护平台', content: '特高压重症监护平台采用开放式系统，系统设备配置和功能满足无人值班技术要求', url: {path: '/productDetails', query: {id: 2}} , src: require('../assets/images/product/红外重症监护平台.png')},
        // {position: 'left', title: '微环境状态监测平台', content: '微环境状态监测平台采用开放式系统，系统设备配置和功能满足无人值班技术要求', url: {path: '/productDetails', query: {id: 3}} , src: require('../assets/images/product/微环境状态检测平台.png')},
        // {position: 'right', title: 'AI视觉“精确识别”', content: 'AI视觉精确识别目前应用最为成熟的领域毋庸置疑是人脸识别，同时还包括电商网站，搜索引擎上的根据图片来检索出商品信息，并提供推荐的商品链接。目前在电力系统的生产运行过程中，用到的视觉技术主要是用于设备测温，AI视觉识别技术主要用于故障的识别和危险行为告警', url: {path: '/productDetails', query: {id: 4}} , src: require('../assets/images/product/AI视觉精确识别.png')},
        // {position: 'left', title: '为变电站提供服务', content: '变电站辅助设备监控系统部署在变电站，包括辅助设备监控系统主机、工作站等主要设备，集成变电站 安防、消防、动环、锁控及状态监测信息，实现辅助设备数据采集、运行监视、操作控制、对时、权限、配置、数据存储、报表以及智能联动管理，为变电站综合监控提供辅助信息支撑', url: {path: '/productDetails', query: {id: 5}} , src: require('../assets/images/product/为变电站提供服务.png')},
        // {position: 'right', title: '充电桩主动防御装置', content: '设备能够有效提升电网的向充电桩输电的效率，在同等负载的情况下，直接输出功率能够提高10%；同时按照现有技术导则计算，同等负载条件下至少可以多安装2倍以上充电桩。能够实现电力公司，物业，电动车业主的多赢局面，同时能够有帮助电动车的更快速的推广，助力早日达成“双碳”目标', url: {path: '/productDetails', query: {id: 6}} , src: require('../assets/images/product/充电桩主动防御装置.png')},
        {position: 'left', title: '变电站智慧辅控管理平台', content: 'LOGI-SACT', url: {path: '/platform', query: {id: 1}} , src: require('../assets/images/2023/product/首页产品_1.png')},
        {position: 'right', title: '变电站远程智能巡视系统', content: '变电站远程智能巡视系统', url: {path: '/platform', query: {id: 2}} , src: require('../assets/images/2023/product/首页产品_2.png')},
        {position: 'left', title: '智能电子安防监控平台', content: '智能电子安防监控平台', url: {path: '/platform', query: {id: 3}} , src: require('../assets/images/2023/product/首页产品_4.png')},
        {position: 'right', title: '变电站表计数字化远程监视平台', content: '变电站表计数字化远程监视平台', url: {path: '/platform', query: {id: 4}} , src: require('../assets/images/2023/product/首页产品_3.png')},
      ],
      guardianship: [
        {position: 'right', title: '变电设备红外重症监护装置', content: 'XM-IR-ICU', url: {path: '/guardianship', query: {id: 1}} , src: require('../assets/images/2023/product/首页产品_5.png')},
        {position: 'left', title: '电气柜防潮防凝露专项解决方案', content: '专项解决方案', url: {path: '/guardianship', query: {id: 2}} , src: require('../assets/images/2023/product/首页产品_6.png')},
        {position: 'right', title: '自组网温湿度及积水监控', content: 'XM-WSD', url: {path: '/guardianship', query: {id: 3}} , src: require('../assets/images/2023/product/首页产品_7.png')},
      ],
      activeName: 'first'
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
}
</script>

<style scoped>
.new-product .title{text-indent: 2em; line-height: 2; margin-bottom: 2rem;}
.new-product /deep/ .el-tabs__item {font-size: 1.2rem;}
</style>